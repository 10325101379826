<template>
  <v-app>
    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ title }}
            </span>
          </h5>

          <b-form-group
            id="input-group-name"
            label="Nama:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="form.name"
              placeholder="Nama"
            ></b-form-input>
            <small class="text-danger">{{ error.name }}</small>
          </b-form-group>

          <b-form-group id="input-group-description">
            <label for="input-description"
              >Deskripsi: <em class="text-muted">opsional</em></label
            >
            <b-form-textarea
              id="input-description"
              v-model="form.description"
              placeholder="Deskripsi"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.description }}</small>
          </b-form-group>

          <div class="row">
            <div class="col-md-6 col-sm-12 pb-0">
              <b-form-group
                id="input-group-start-date"
                label="Mulai Event:"
                label-for="input-start-date"
              >
                <v-datetime-picker
                  v-model="start_time"
                  :time-picker-props="timeProps"
                  time-format="HH:mm:ss"
                  @input="setStartTime"
                ></v-datetime-picker>
                <small class="text-danger">{{ error.start_time }}</small>
              </b-form-group>
            </div>
            <div class="col-md-6 col-sm-12 pb-0">
              <b-form-group
                id="input-group-end-date"
                label="Selesai Event:"
                label-for="input-end-date"
              >
                <v-datetime-picker
                  v-model="end_time"
                  :time-picker-props="timeProps"
                  time-format="HH:mm:ss"
                  @input="setEndTime"
                ></v-datetime-picker>
                <small class="text-danger">{{ error.end_time }}</small>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col-sm-12 pb-0">
              <b-form-group
                id="input-group-start-pitching-date"
                label="Mulai Discussion:"
                label-for="input-start-pitching-date"
              >
                <v-datetime-picker
                  v-model="start_pitching"
                  :time-picker-props="timeProps"
                  time-format="HH:mm:ss"
                  @input="setStartPitching"
                ></v-datetime-picker>
                <small class="text-danger">{{ error.start_pitching }}</small>
              </b-form-group>
            </div>
            <div class="col-md-6 col-sm-12 pb-0">
              <b-form-group
                id="input-group-end-pitching-date"
                label="Selesai Discussion:"
                label-for="input-end-pitching-date"
              >
                <v-datetime-picker
                  v-model="end_pitching"
                  :time-picker-props="timeProps"
                  time-format="HH:mm:ss"
                  @input="setEndPitching"
                ></v-datetime-picker>
                <small class="text-danger">{{ error.end_pitching }}</small>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label class="col-xl-3 col-lg-8 col-form-label pl-0"
                  >Gambar Thumbnail</label
                >
                <div class="col-lg-9 col-xl-6 pl-0 pt-0">
                  <div
                    class="image-input image-input-outline"
                    id="kt_profile_avatar"
                  >
                    <div
                      class="image-input-wrapper image-input-wrapper-thumbnail"
                    >
                      <img :src="thumbnail" alt="" />
                    </div>
                    <label
                      class="
                        btn
                        btn-xs
                        btn-icon
                        btn-circle
                        btn-white
                        btn-hover-text-primary
                        btn-shadow
                      "
                      data-action="change"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Change avatar"
                    >
                      <i class="fa fa-pen icon-sm text-muted"></i>
                      <input
                        type="file"
                        name="profile_avatar"
                        accept=".png, .jpg, .jpeg"
                        @change="onThumbnail($event)"
                      />
                      <input type="hidden" name="profile_avatar_remove" />
                    </label>
                    <span
                      class="
                        btn
                        btn-xs
                        btn-icon
                        btn-circle
                        btn-white
                        btn-hover-text-primary
                        btn-shadow
                      "
                      data-action="cancel"
                      data-toggle="tooltip"
                      title="Cancel avatar"
                    >
                      <i class="ki ki-bold-close icon-xs text-muted"></i>
                    </span>
                    <span
                      class="
                        btn
                        btn-xs
                        btn-icon
                        btn-circle
                        btn-white
                        btn-hover-text-primary
                        btn-shadow
                      "
                      data-action="remove"
                      data-toggle="tooltip"
                      title="Remove avatar"
                      @click="onRemoveThumbnail()"
                    >
                      <i class="ki ki-bold-close icon-xs text-muted"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="col-xl-3 col-lg-4 col-form-label pl-0"
                  >Gambar Header</label
                >
                <div class="col-lg-9 col-xl-6 pl-0 pt-0">
                  <div
                    class="image-input image-input-outline"
                    id="kt_profile_avatar"
                  >
                    <div class="image-input-wrapper image-input-wrapper-header">
                      <img :src="photo" alt="" />
                    </div>
                    <label
                      class="
                        btn
                        btn-xs
                        btn-icon
                        btn-circle
                        btn-white
                        btn-hover-text-primary
                        btn-shadow
                      "
                      data-action="change"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Change avatar"
                    >
                      <i class="fa fa-pen icon-sm text-muted"></i>
                      <input
                        type="file"
                        name="profile_avatar"
                        accept=".png, .jpg, .jpeg"
                        @change="onFileChange($event)"
                      />
                      <input type="hidden" name="profile_avatar_remove" />
                    </label>
                    <span
                      class="
                        btn
                        btn-xs
                        btn-icon
                        btn-circle
                        btn-white
                        btn-hover-text-primary
                        btn-shadow
                      "
                      data-action="cancel"
                      data-toggle="tooltip"
                      title="Cancel avatar"
                    >
                      <i class="ki ki-bold-close icon-xs text-muted"></i>
                    </span>
                    <span
                      class="
                        btn
                        btn-xs
                        btn-icon
                        btn-circle
                        btn-white
                        btn-hover-text-primary
                        btn-shadow
                      "
                      data-action="remove"
                      data-toggle="tooltip"
                      title="Remove avatar"
                      @click="onRemove()"
                    >
                      <i class="ki ki-bold-close icon-xs text-muted"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="$router.push('/pitching-events')"
            >
              Batal
            </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import moment from 'moment';

export default {
  props: {
    form: Object,
    route: String,
    title: String,
    purpose: {
      type: String,
      default: "add",
    },
  },
  data() {
    return {
      error: {
        name: "",
        description: "",
        start_time: "",
        end_time: "",
        start_pitching: "",
        end_pitching: "",
        status_id: "",
        image_header: "",
        image_thumbail: "",
      },
      timeProps: {
        useSeconds: true,
        ampmInTitle: true,
      },
      start_time: new Date(),
      end_time: new Date(),
      start_pitching: new Date(),
      end_pitching: new Date(),
      current_thumbnail: "/img/default/classroom_t.png",
      current_photo: "/img/default/classroom_h.png",
      default_header: "/img/default/classroom_h.png",
      default_thumbnail: "/img/default/classroom_t.png",
    };
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.form.image_header = e.target.files[0];

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onThumbnail(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.form.image_thumbnail = e.target.files[0];

        reader.onload = (event) => {
          this.current_thumbnail = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onRemove() {
      this.current_photo = null;
      this.form.image_header = null;
    },
    onRemoveThumbnail() {
      this.current_thumbnail = null;
      this.form.image_thumbnail = null;
    },
    setStartTime(evt){
      let start_time = moment(evt).format('YYYY-MM-DD HH:mm:ss')
      this.form.start_time = start_time
    },
    setEndTime(evt){
      let end_time = moment(evt).format('YYYY-MM-DD HH:mm:ss')
      this.form.end_time = end_time
    },
    setStartPitching(evt){
      let start_pitching = moment(evt).format('YYYY-MM-DD HH:mm:ss')
      this.form.start_pitching = start_pitching
    },
    setEndPitching(evt){
      let end_pitching = moment(evt).format('YYYY-MM-DD HH:mm:ss')
      this.form.end_pitching = end_pitching
    },

    validationMessage() {
      Swal.fire({
        title: "Gagal",
        text: "Harap periksa kembali form",
        icon: "error",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },

    async formOnsubmit() {

      if(this.form.start_time >= this.form.end_time){
        this.error.end_time = "Kolom selesai harus lebih lama dari kolom mulai"
        this.validationMessage()
        throw new Error("error")
      }else{
        this.error.end_time = ""
      }

      if(this.form.start_pitching >= this.form.end_pitching){
        this.error.end_pitching = "Kolom selesai harus lebih lama dari kolom mulai"
        this.validationMessage()
        throw new Error("error")
      }else{
        this.error.end_pitching = ""
      }

      if(this.form.end_pitching > this.form.end_time){
        this.error.end_pitching = "waktu discussion tidak boleh lebih lama dari waktu event"
        this.validationMessage()
        throw new Error("error")
      }else{
        this.error.end_pitching = ""
      }

      if(this.form.start_pitching < this.form.start_time){
        this.error.start_pitching = "waktu discussion tidak boleh lebih awal dari waktu event"
        this.validationMessage()
        throw new Error("error")
      }else{
        this.error.start_pitching = ""
      }

      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }

      // Make Request
      let response = await module.submit(formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$router.push("/pitching-events");
      }
    },
  },
  mounted() {},
  computed: {
    photo() {
      return this.current_photo == null
        ? this.default_header
        : this.current_photo;
    },
    thumbnail() {
      return this.current_thumbnail == null
        ? this.default_thumbnail
        : this.current_thumbnail;
    },
  },
  watch: {
    form: function (newVal, oldVal) {
      if (this.purpose == "edit") {
        this.start_time = new Date(this.form.start_time);
        this.end_time = new Date(this.form.end_time);
        this.start_pitching = new Date(this.form.start_pitching);
        this.end_pitching = new Date(this.form.end_pitching);

        this.default_header = this.form.image_header;
        this.current_photo = this.form.image_header;
        this.default_thumbnail = this.form.image_thumbnail;
        this.current_thumbnail = this.form.image_thumbnail;
      }
    },
  },
};
</script>

<style scoped>
.image-input-wrapper-thumbnail {
  width: 232px !important;
  height: 232px !important;
}

.image-input-wrapper-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-input-wrapper-header {
  width: 280px !important;
  height: 120px !important;
}

.image-input-wrapper-header img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>